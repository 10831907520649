








































































































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { useElementVisibility } from "@vueuse/core";
import type { IomodelsOverviewFilter } from "@/lib/backend/club.api";
import { getCoachName } from "@/lib/getCoachName";
import { LOCATION_DEFAULT, useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { buttonVariants } from "@/lib/pfg/components/button";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import { queries } from "@/lib/query/queries";
import { flattenMembers } from "@/lib/query/queries/overview";
import { getIconName } from "@/utils/images";

export default defineComponent({
	components: { Label },
	setup() {
		const loadMoreButton = ref<HTMLButtonElement | null>(null);
		const loadMoreButtonIsVisible = useElementVisibility(loadMoreButton);
		const { data: gyms } = useQuery(queries.gym.all());

		const { gym: currentGym } = useCurrentGym();

		const filter_search = ref<string>("");
		const filter_gym = ref<number | undefined>(currentGym.value?.id);
		const filter_coach = ref<number | undefined>(undefined);
		const filter_group = ref<NonNullable<IomodelsOverviewFilter["coachingsGroep"]>>();

		const filterValue_gym = computed(
			() =>
				gyms.value?.find((gym) => gym.id === filter_gym.value)?.slug ??
				currentGym.value?.slug ??
				LOCATION_DEFAULT.slug,
		);

		const { data: coaches } = useQuery({
			...queries.coach.all({ gymId: filter_gym.value }),
			queryKey: computed(() => queries.coach.all({ gymId: filter_gym.value }).queryKey),
		});

		const { status, data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
			...queries.overview.infinite.members({
				vestiging: filter_gym.value ? filterValue_gym.value : "",
				search: filter_search.value,
				sort: "check-in",
				coachingsGroep: filter_group.value,
				coachId: filter_coach.value,
			}),
			queryKey: computed(
				() =>
					queries.overview.infinite.members({
						vestiging: filter_gym.value ? filterValue_gym.value : "",
						search: filter_search.value,
						sort: "check-in",
						coachingsGroep: filter_group.value,
						coachId: filter_coach.value,
					}).queryKey,
			),
		});

		const members = computed(() => flattenMembers(data.value));

		watch(
			computed(() => ({
				status: status.value,
				hasNextPage: hasNextPage?.value,
				loadMoreButtonIsVisible: loadMoreButtonIsVisible.value,
			})),
			(value) => {
				if (value.status !== "success" || !value.hasNextPage || !value.loadMoreButtonIsVisible) {
					return;
				}

				fetchNextPage();
			},
			{ deep: true, immediate: true },
		);

		function resetFilters() {
			filter_gym.value = currentGym.value?.id;
			filter_coach.value = undefined;
			filter_group.value = undefined;
			filter_search.value = "";
		}

		watch(filter_gym, () => (filter_coach.value = undefined));

		return {
			buttonVariants,
			loadMoreButton,
			filter_gym,
			filter_coach,
			filter_group,
			filter_search,
			resetFilters,
			status,
			members,
			hasNextPage,
			isFetchingNextPage,
			fetchNextPage,
			getIconName,
			getCoachName,
			gyms,
			coaches,
		};
	},
});
